.costBlock {
    width: 1024px;
    height: 2261px;
    flex-shrink: 0;
    background: #6DFFFF;
}

.titleMain {
    padding-right: 150px;
    color: #54000E;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 99.841px;
    font-style: normal;
    font-weight: 800;
    line-height: 149.9%;
    /* 149.661px */
    text-transform: uppercase;
}

.titleSecond {
    padding-left: 200px;
    color: #54000E;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 80.871px;
    font-style: normal;
    font-weight: 600;
    line-height: 149.9%;
    /* 121.226px */
    text-transform: uppercase;
}

.imgMain {
    position: absolute;
    margin-top: -100px;
    margin-left: -50px;
    width: 750px;
    height: 944px;
    flex-shrink: 0;
}

.blockRedOne {
    margin-left: 185px;
    margin-top: 129px;
    width: 620px;
    height: 117px;
    flex-shrink: 0;
    border-radius: 30px;
    background: #FF2147;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.18), 0px 5px 12px 0px rgba(0, 0, 0, 0.18), 0px 21px 21px 0px rgba(0, 0, 0, 0.15), 0px 48px 29px 0px rgba(0, 0, 0, 0.09), 0px 86px 34px 0px rgba(0, 0, 0, 0.03), 0px 134px 37px 0px rgba(0, 0, 0, 0.00);
}

.titleBlockRedOne {
    padding-top: 20px;
    margin-left: 350px;
    color: #FFF;
    font-family: Montserrat Alternates;
    font-size: 59.896px;
    font-style: normal;
    font-weight: 800;
    line-height: 119.9%;
    /* 71.816px */
    text-transform: uppercase;
}

.blockRedTwo {
    margin-left: 370px;
    margin-top: 50px;
    width: 498px;
    height: 117px;
    flex-shrink: 0;
    border-radius: 30px;
    background: #FF2147;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.18), 0px 5px 12px 0px rgba(0, 0, 0, 0.18), 0px 21px 21px 0px rgba(0, 0, 0, 0.15), 0px 48px 29px 0px rgba(0, 0, 0, 0.09), 0px 86px 34px 0px rgba(0, 0, 0, 0.03), 0px 134px 37px 0px rgba(0, 0, 0, 0.00);
}

.titleBlockRedTwo {
    padding-top: 18px;
    margin-left: 90px;
    color: #FFF;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 35.551px;
    font-style: normal;
    font-weight: 800;
    line-height: 119.9%;
    /* 42.625px */
    text-transform: uppercase;
}

.blockRedThree {
    margin-left: 350px;
    margin-top: 50px;
    width: 574px;
    height: 117px;
    flex-shrink: 0;
    border-radius: 30px;
    background: #FF2147;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.18), 0px 5px 12px 0px rgba(0, 0, 0, 0.18), 0px 21px 21px 0px rgba(0, 0, 0, 0.15), 0px 48px 29px 0px rgba(0, 0, 0, 0.09), 0px 86px 34px 0px rgba(0, 0, 0, 0.03), 0px 134px 37px 0px rgba(0, 0, 0, 0.00);
}

.titleBlockRedThree {
    padding-top: 25px;
    margin-left: 220px;
    color: #FFF;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 28.255px;
    font-style: normal;
    font-weight: 800;
    line-height: 119.9%;
    /* 33.878px */
    text-transform: uppercase;
}

.blockRedFour {
    margin-left: 370px;
    margin-top: 50px;
    width: 611px;
    height: 117px;
    flex-shrink: 0;
    border-radius: 30px;
    background: #FF2147;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.18), 0px 5px 12px 0px rgba(0, 0, 0, 0.18), 0px 21px 21px 0px rgba(0, 0, 0, 0.15), 0px 48px 29px 0px rgba(0, 0, 0, 0.09), 0px 86px 34px 0px rgba(0, 0, 0, 0.03), 0px 134px 37px 0px rgba(0, 0, 0, 0.00);
}

.titleBlockRedFour {
    padding-top: 25px;
    margin-left: 230px;
    color: #FFF;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 28.332px;
    font-style: normal;
    font-weight: 800;
    line-height: 119.9%;
    /* 33.97px */
    text-transform: uppercase;
}

.lengthCourseBlock {
    margin-top: 95px;
    margin-left: 90px;
    width: 843px;
    height: 364px;
    flex-shrink: 0;
    border-radius: 30px;
    background: #6DFFFF;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.22), 0px -9px 19px 0px rgba(0, 0, 0, 0.22), 0px -34px 34px 0px rgba(0, 0, 0, 0.19), 0px -77px 46px 0px rgba(0, 0, 0, 0.11), 0px -137px 55px 0px rgba(0, 0, 0, 0.03), 0px -214px 60px 0px rgba(0, 0, 0, 0.00);
}

.text21Day {
    padding-top: 80px;
    text-align: center;
    color: #54000E;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 33.306px;
    font-style: normal;
    font-weight: 800;
    line-height: 119.9%;
    /* 39.933px */
    text-transform: uppercase;
}

.text30minet {
    padding-top: 30px;
    text-align: center;
    color: #54000E;
    font-family: Montserrat Alternates;
    font-size: 41.144px;
    font-style: normal;
    font-weight: 800;
    line-height: 119.9%;
    text-transform: uppercase;
}

.priceListBlock {
    margin: 0 auto;
    margin-top: 100px;
    width: 666px;
    height: 293px;
    flex-shrink: 0;
    border-radius: 30px;
    background: #6DFFFF;
    box-shadow: 0px 0px 0px 0px rgba(26, 50, 50, 0.14), 0px 9px 19px 0px rgba(26, 50, 50, 0.14), 0px 35px 35px 0px rgba(26, 50, 50, 0.12), 0px 79px 47px 0px rgba(26, 50, 50, 0.07), 0px 140px 56px 0px rgba(26, 50, 50, 0.02), 0px 218px 61px 0px rgba(26, 50, 50, 0.00);
}

.priceListBlockTitle {
    padding-top: 30px;
    color: #54000E;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 42.934px;
    font-style: normal;
    font-weight: 700;
    line-height: 144.4%;
    /* 61.997px */
    text-transform: uppercase;
}

.costsBlock {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.priceSale {
    margin-top: 50px;
    text-decoration: line-through;
    color: #54000E;
    text-align: center;

    font-family: Montserrat Alternates;
    font-size: 46.645px;
    font-style: normal;
    font-weight: 700;
}

.priceMain {
    margin-top: 50px;
    color: #54000E;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 46.645px;
    font-style: normal;
    font-weight: 700;
}

.imgSecondBlockPrice {
    margin-top: -120px;
    margin-left: 40px;
    width: 432px;
    height: 238px;
    flex-shrink: 0;
}

.btnBlock {
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.btn {
    width: 561.148px;
    height: 146px;
    flex-shrink: 0;
    border-radius: 38.087px;
    background: #FF2147;
    box-shadow: 0px 0px 0px 0px rgba(26, 50, 50, 0.14), 0px 11.426087379455566px 24.12173843383789px 0px rgba(26, 50, 50, 0.14), 0px 44.434783935546875px 44.434783935546875px 0px rgba(26, 50, 50, 0.12), 0px 100.295654296875px 59.6695671081543px 0px rgba(26, 50, 50, 0.07), 0px 177.7391357421875px 71.09564971923828px 0px rgba(26, 50, 50, 0.02), 0px 276.7652282714844px 77.4434814453125px 0px rgba(26, 50, 50, 0.00);
}

.btnText {
    text-decoration:none;
    padding-bottom: 50%;
    margin: 0 auto;
    width: 354px;
    color: #FFF;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 47.621px;
    font-style: normal;
    font-weight: 700;
    line-height: 20%;
    /* 52.812px */
    text-transform: uppercase;
}

.specialForYouBlock {
    margin-top: 50px;
    width: 1024px;
    height: 838px;
    flex-shrink: 0;
    background: #FF2147;
}

.descriptionText {
    padding-top: 50px;
    padding-left: 50px;
    width: 698px;
    height: 551px;
    flex-shrink: 0;
    color: #FFF;
    font-family: Montserrat;
    font-size: 43.484px;
    font-style: normal;
    font-weight: 800;
    line-height: 135.9%;
    /* 59.095px */
    font-variant: small-caps;
}

.imgspecialForYou {
    margin-top: -750px;
    margin-left: 424px;
    width: 600px;
    height: 900px;
    flex-shrink: 0;
}

.samarskaiaContainer {
    padding-top: 100px;
    width: 1024px;
    height: 4546px;
    flex-shrink: 0;
    background: #6DFFFF;
}

.samarskaiaBlock {
    display: flex;
    justify-content: space-evenly;

}

.sashaBlock {
    width: 471px;
    height: 557px;
    flex-shrink: 0;
    border-radius: 30px;
    background: #FF2147;
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.30)) drop-shadow(0px 9px 20px rgba(0, 0, 0, 0.29)) drop-shadow(0px 37px 37px rgba(0, 0, 0, 0.26)) drop-shadow(0px 83px 50px rgba(0, 0, 0, 0.15)) drop-shadow(0px 147px 59px rgba(0, 0, 0, 0.04)) drop-shadow(0px 230px 64px rgba(0, 0, 0, 0.01));
}

.sandraImg {
    margin-top: 35px;
    margin-left: 45px;
    width: 380px;
    height: 482px;
    flex-shrink: 0;
    border-radius: 30px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.30), 0px 8px 18px 0px rgba(0, 0, 0, 0.29), 0px 33px 33px 0px rgba(0, 0, 0, 0.26), 0px 75px 45px 0px rgba(0, 0, 0, 0.15), 0px 134px 54px 0px rgba(0, 0, 0, 0.04), 0px 209px 59px 0px rgba(0, 0, 0, 0.01);
}

.aboutMeBlock {
    width: 471px;
    height: 557px;
    flex-shrink: 0;
    border-radius: 30px;
    background: #FF2147;
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.30)) drop-shadow(0px 9px 20px rgba(0, 0, 0, 0.29)) drop-shadow(0px 37px 37px rgba(0, 0, 0, 0.26)) drop-shadow(0px 83px 50px rgba(0, 0, 0, 0.15)) drop-shadow(0px 147px 59px rgba(0, 0, 0, 0.04)) drop-shadow(0px 230px 64px rgba(0, 0, 0, 0.01));
}

.croupOne {
   margin-left: -80px;
    margin-top:50px;
    display: flex;
    justify-content:center;
    align-items: center;


}

.poseImgOne {
    padding-bottom: 20px;
    margin-right: 180px;
    width: 85px;
    height: 91px;
    flex-shrink: 0;
    
}
.poseImgTwo{
    width: 92px;
    height: 116px;
    flex-shrink: 0;
}
.poseImgThree{
    margin-top: 50px;
    margin-left: 30px;
    width: 105px;
    height: 116px;
    flex-shrink: 0;
    
}

.poseTextOne {
    margin-left: -170px;
    color: #FFF;
    font-family: Montserrat Alternates;
    font-size: 33.306px;
    font-style: normal;
    font-weight: 800;
    line-height: 119.9%;
    /* 39.933px */
    text-transform: uppercase;
}
.poseTextTwo{
    color: #FFF;
    font-family: Montserrat Alternates;
    font-size: 28.94px;
    font-style: normal;
    font-weight: 800;
    line-height: 119.9%;
    /* 39.933px */
    text-transform: uppercase;
}
.poseTextThree{
    width: 311px;
   height: 186px;
    margin-top: 50px;
    color: #FFF;
    font-family: Montserrat Alternates;
    font-size: 25.48px;
    font-style: normal;
    font-weight: 800;
    line-height: 119.9%;
    /* 39.933px */
    text-transform: uppercase;
}

.croupTwo {
    display: flex;
    justify-content: center;
    align-items: center;

}

.croupThree {
    margin-left: 1px;
    display: flex;
    justify-content: center;
    margin-right: 30px;
    
}

.metodicTitle {
    padding-top: 50px;
    color: #54000E;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 44.788px;
    font-style: normal;
    font-weight: 600;
    line-height: 119.9%;
    /* 53.701px */
    text-transform: uppercase;
}

.threeInOne {
    margin-top: 30px;
    color: #54000E;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 77.472px;
    font-style: normal;
    font-weight: 800;
    line-height: 119.9%;
    /* 92.889px */
    text-transform: uppercase;
}

.metodicBlock {
    margin-left: 375px;
    margin-top: -200px;
    width: 272px;
    height: 749px;
    transform: rotate(90deg);
    flex-shrink: 0;
    border-radius: 30px;
    background: #FF2147;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.22), 0px 12px 27px 0px rgba(0, 0, 0, 0.22), 0px 49px 49px 0px rgba(0, 0, 0, 0.19), 0px 110px 66px 0px rgba(0, 0, 0, 0.11), 0px 196px 78px 0px rgba(0, 0, 0, 0.03), 0px 306px 86px 0px rgba(0, 0, 0, 0.00);
}

.metodicText {
    padding-top: 70px;
    margin-left: -400px;
    margin-top: 230px;
    position: absolute;
    transform: rotate(-90deg);
    width: 1000px;
    color: #FFF;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 44.788px;
    font-style: normal;
    font-weight: 800;
    line-height: 119.9%;
    /* 53.701px */
    text-transform: uppercase;
}

.descriptionBlockFooter {
    margin: 0 auto;
    width: 968px;
    height: 236px;
    flex-shrink: 0;
    border-radius: 16.42px;
    border: 2.737px solid #54000E;
}

.descriptionTextFooter {
    padding-top: 30px;
     margin: 0 auto;
    width: 864px;
    color: #54000E;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 27.777px;
    font-style: normal;
    font-weight: 600;
    line-height: 119.9%;
    /* 33.305px */
    text-transform: uppercase;
}