.footerBlock{

}
.textMeBlock{
    margin-top: 300px;
    margin-left: -170px;
display: flex;
flex-direction:row;
align-items: center;
justify-content:center;
}
.textMeIMG{
    width: 94px;
height: 94px;
flex-shrink: 0;
}
.textMeText{
    text-decoration: none;
    margin-top: 30px;
    margin-left: 20px;
    color: #54000E;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 54.571px;
    font-style: normal;
    font-weight: 600;
    line-height: 119.9%; /* 65.431px */
    text-transform: uppercase;
}
.logoImgFooter{
    margin-top: -400px;
    margin-left: 724px;
    width: 300px;
height: 455px;
flex-shrink: 0;
}