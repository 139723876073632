@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@700;900&display=swap');

.courseBlock {
    margin: 0 auto;
    /* display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap; */
    width: 1024px;
    height: 2270px;
    flex-shrink: 0;
    background-color: #A7FFFF;
}

.iconsBlock {
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.textFirst {
    color: #54000E;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 52.353px;
    font-style: normal;
    font-weight: 800;
    line-height: 191.9%;
    /* 100.466px */
    text-transform: uppercase;
}

.textSecond {
    color: #54000E;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 52.353px;
    font-style: normal;
    font-weight: 800;
    line-height: 191.9%;
    /* 100.466px */
    text-transform: uppercase;
}

.asanaImgBlock {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;


}

.asanaImg {
    width: 800px;
    height: 800px;
    flex-shrink: 0;
    border-radius: 30px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.26), 0px 11px 24px 0px rgba(0, 0, 0, 0.26), 0px 43px 43px 0px rgba(0, 0, 0, 0.22), 0px 97px 58px 0px rgba(0, 0, 0, 0.13), 0px 172px 69px 0px rgba(0, 0, 0, 0.04), 0px 269px 75px 0px rgba(0, 0, 0, 0.00);
}

.textTimeOfCoureseBlock {
    display: flex;
    align-items: center;
    justify-content: first baseline;
    flex-wrap: wrap;
    flex-flow: column;
}

.textTimeOfCoureseOne {
    color: #54000E;
    text-align: center;
    font-family: Montserrat;
    font-size: 36.759px;
    font-style: normal;
    font-weight: 700;
    line-height: 144.4%;
    /* 53.08px */
}

.textTimeOfCoureseTwo {
    color: #54000E;
    font-family: Montserrat;
    font-size: 43.969px;
    font-style: normal;
    font-weight: 700;
    line-height: 144.4%;
    text-transform: uppercase;
}

.inventoryBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 620px;
    height: 276.486px;
    flex-shrink: 0;
}

.logoImg {
    width: 221.646px;
    height: 276.486px;
    flex-shrink: 0;
    position: absolute;
}

.inventaryTextBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 90px;
    margin-left: 500px;
    width: 509.558px;
    height: 146.241px;
    flex-shrink: 0;
    border-radius: 16.42px;
    border: 2.737px solid #594E2B;
    color: #594E2B;
}

.importantInventaryText {
    padding-left: 80px;
    color: #54000E;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 27.998px;
    font-style: normal;
    font-weight: 900;
    line-height: 144.4%;
    /* 40.43px */
}

.inventaryText {
    padding-left: 80px;
    color: #54000E;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 27.998px;
    font-style: normal;
    font-weight: 600;
    /* 40.43px */
    text-transform: uppercase;
}

.priceBlock {
    margin-left: 200px;
    width: 666px;
    height: 293px;
    flex-shrink: 0;
    border-radius: 30px;
    background: #6DFFFF;
    box-shadow: 0px 0px 0px 0px rgba(26, 50, 50, 0.14), 0px 9px 19px 0px rgba(26, 50, 50, 0.14), 0px 35px 35px 0px rgba(26, 50, 50, 0.12), 0px 79px 47px 0px rgba(26, 50, 50, 0.07), 0px 140px 56px 0px rgba(26, 50, 50, 0.02), 0px 218px 61px 0px rgba(26, 50, 50, 0.00);
}

.priceMainText {
    padding-top: 30px;
    color: #54000E;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 42.934px;
    font-style: normal;
    font-weight: 700;
    line-height: 144.4%;
    text-transform: uppercase;

}

.costsBlock {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.priceSale {
    margin-top: 50px;
    text-decoration: line-through;
    color: #54000E;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 46.645px;
    font-style: normal;
    font-weight: 700;
}

.priceMain {
    margin-top: 50px;
    color: #54000E;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 46.645px;
    font-style: normal;
    font-weight: 700;
}

.btnBlock {
    padding: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.btn {
    width: 561.148px;
    height: 146px;
    flex-shrink: 0;
    border-radius: 38.087px;
    background: #FF2147;
    box-shadow: 0px 0px 0px 0px rgba(26, 50, 50, 0.14), 0px 11.426087379455566px 24.12173843383789px 0px rgba(26, 50, 50, 0.14), 0px 44.434783935546875px 44.434783935546875px 0px rgba(26, 50, 50, 0.12), 0px 100.295654296875px 59.6695671081543px 0px rgba(26, 50, 50, 0.07), 0px 177.7391357421875px 71.09564971923828px 0px rgba(26, 50, 50, 0.02), 0px 276.7652282714844px 77.4434814453125px 0px rgba(26, 50, 50, 0.00);
}

.btnText {
    text-decoration:none;
    padding-bottom: 50px;
    margin: 0 auto;
    width: 354px;
    color: #FFF;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 47.621px;
    font-style: normal;
    font-weight: 700;
    line-height: 20%;
    /* 52.812px */
    text-transform: uppercase;
}

