.guideContainer {
    margin: 0 auto;
    padding-top: 50px;
    width: 1021px;
    height: 16814px;
    flex-shrink: 0;
    background: #FF5B77;
}

.guideBlock {
    border-radius: 27px;
    border: 13px solid #54000E;
    margin: 0 auto;
    width: 836px;
    height: 3642px;
    flex-shrink: 0;
    flex-shrink: 0;
    background: #A7FFFF;
}

.dowloadBlock {
    padding-top: 30px;
    display: flex;
    justify-content: space-evenly;

}

.dowloadText {
    color: #54000E;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 29.887px;
    font-style: normal;
    font-weight: 800;
    line-height: 191.9%;
    /* 57.353px */
    text-transform: uppercase;
}

.dowloadIcon {
    width: 61px;
    height: 61px;
    flex-shrink: 0;
}

.title {
    padding-top: 50px;
    color: #54000E;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 52.353px;
    font-style: normal;
    font-weight: 800;
    line-height: 191.9%;
    /* 100.466px */
    text-transform: uppercase;
}

.plus {
    color: #54000E;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 52.353px;
    font-style: normal;
    font-weight: 800;
    line-height: 191.9%;
    /* 100.466px */
    text-transform: uppercase;
}


.btn {
    margin-left: 95px;
    width: 685.356px;
    height: 140.502px;
    flex-shrink: 0;
    border-radius: 27.363px;
    background: #FF2147;
    box-shadow: 0px 0px 0px 0px rgba(26, 50, 50, 0.14), 0px 8.20887px 17.32984px 0px rgba(26, 50, 50, 0.14), 0px 31.9234px 31.9234px 0px rgba(26, 50, 50, 0.12), 0px 72.05566px 42.86856px 0px rgba(26, 50, 50, 0.07), 0px 127.69358px 51.07743px 0px rgba(26, 50, 50, 0.02), 0px 198.83714px 55.63792px 0px rgba(26, 50, 50, 0.00);
}

.btnText {
    color: #FFF;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 31.599px;
    font-style: normal;
    font-weight: 800;
    line-height: 135.9%;
    /* 42.943px */
    text-transform: uppercase;
}

.blockOne {
    margin-right: 50px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.one {
    color: #FF2147;
    text-align: center;
    text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.26), 0px 3.930147647857666px 8.574867248535156px rgba(0, 0, 0, 0.26), 0px 15.720590591430664px 15.720590591430664px rgba(0, 0, 0, 0.22), 0px 35.0140380859375px 21.07988166809082px rgba(0, 0, 0, 0.13), 0px 62.52507400512695px 25.010026931762695px rgba(0, 0, 0, 0.04), 0px 97.53911590576172px 27.511032104492188px rgba(0, 0, 0, 0.00);
    font-family: Montserrat Alternates;
    font-size: 118.363px;
    font-style: normal;
    font-weight: 800;
    line-height: 191.9%;
    /* 227.139px */
    text-transform: uppercase;
}

.oneProjent {
    color: #54000E;
    font-family: Montserrat Alternates;
    font-size: 33.545px;
    font-style: normal;
    font-weight: 800;
    line-height: 135.9%;
    /* 45.587px */
    text-transform: lowercase;
}

.oneDescription {
    margin-left: -50px;
    color: #54000E;
    font-family: Montserrat Alternates;
    font-size: 27.402px;
    font-style: normal;
    font-weight: 600;
    line-height: 135.9%;
    text-transform: lowercase;
}

.blockTwo {
    margin-right: 50px;
    display: flex;
    justify-content: space-evenly;
    align-items: first baseline;
}

.two {
    color: #FF2147;
    text-align: center;
    text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.26), 0px 3.930147647857666px 8.574867248535156px rgba(0, 0, 0, 0.26), 0px 15.720590591430664px 15.720590591430664px rgba(0, 0, 0, 0.22), 0px 35.0140380859375px 21.07988166809082px rgba(0, 0, 0, 0.13), 0px 62.52507400512695px 25.010026931762695px rgba(0, 0, 0, 0.04), 0px 97.53911590576172px 27.511032104492188px rgba(0, 0, 0, 0.00);
    font-family: Montserrat Alternates;
    font-size: 118.363px;
    font-style: normal;
    font-weight: 800;
    line-height: 191.9%;
    /* 227.139px */
    text-transform: uppercase;

}

.kbzy {
    color: #54000E;
    font-family: Montserrat Alternates;
    font-size: 54.803px;
    font-style: normal;
    font-weight: 800;
    line-height: 135.9%;
    /* 74.478px */
    text-transform: uppercase;
}

.twoDescription {
    margin-left: -50px;
    color: #54000E;
    font-family: Montserrat Alternates;
    font-size: 27.402px;
    font-style: normal;
    font-weight: 600;
    line-height: 135.9%;
    /* 37.239px */
    text-transform: lowercase;
}

.blockThree {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

}

.three {
    color: #FF2147;
    text-align: center;
    text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.26), 0px 3.930147647857666px 8.574867248535156px rgba(0, 0, 0, 0.26), 0px 15.720590591430664px 15.720590591430664px rgba(0, 0, 0, 0.22), 0px 35.0140380859375px 21.07988166809082px rgba(0, 0, 0, 0.13), 0px 62.52507400512695px 25.010026931762695px rgba(0, 0, 0, 0.04), 0px 97.53911590576172px 27.511032104492188px rgba(0, 0, 0, 0.00);
    font-family: Montserrat Alternates;
    font-size: 118.363px;
    font-style: normal;
    font-weight: 800;
    line-height: 191.9%;
    /* 227.139px */
    text-transform: uppercase;
}

.threeDescription {
    margin-top: 50px;
    margin-left: -50px;
    color: #54000E;
    font-family: Montserrat Alternates;
    font-size: 27.402px;
    font-style: normal;
    font-weight: 600;
    line-height: 135.9%;
    /* 37.239px */
    text-transform: lowercase;
}

.ImgforTwoBlock {
    width: 264px;
    height: 194px;
    flex-shrink: 0;
    margin-left: 572px;
    margin-top: -150px;
}

.blockFour {
    margin-left: 50px;
    display: flex;
    justify-content: space-evenly;
    align-items: first baseline;
}

.four {
    color: #FF2147;
    text-align: center;
    text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.26), 0px 3.930147647857666px 8.574867248535156px rgba(0, 0, 0, 0.26), 0px 15.720590591430664px 15.720590591430664px rgba(0, 0, 0, 0.22), 0px 35.0140380859375px 21.07988166809082px rgba(0, 0, 0, 0.13), 0px 62.52507400512695px 25.010026931762695px rgba(0, 0, 0, 0.04), 0px 97.53911590576172px 27.511032104492188px rgba(0, 0, 0, 0.00);
    font-family: Montserrat Alternates;
    font-size: 118.363px;
    font-style: normal;
    font-weight: 800;
    line-height: 191.9%;
    /* 227.139px */
    text-transform: uppercase;
}

.miniBlockRed {
    width: 575.078px;
    height: 94.757px;
    flex-shrink: 0;
    border-radius: 22.352px;
    background: #FF2147;
    box-shadow: 0px 0px 0px 0px rgba(26, 50, 50, 0.14), 0px 6.7056px 14.15627px 0px rgba(26, 50, 50, 0.14), 0px 26.07734px 26.07734px 0px rgba(26, 50, 50, 0.12), 0px 58.86028px 35.01815px 0px rgba(26, 50, 50, 0.07), 0px 104.30936px 41.72374px 0px rgba(26, 50, 50, 0.02), 0px 162.42458px 45.44908px 0px rgba(26, 50, 50, 0.00);
}

.miniBlockRedText {
   padding-top: 10px;
    text-align: center;
    color: #FFF;
    font-family: Montserrat Alternates;
    font-size: 19.903px;
    font-style: normal;
    font-weight: 800;
    line-height: 191.9%;
    /* 38.193px */
    text-transform: uppercase;
}

.fourDescription {
    margin-top: 50px;
    color: #54000E;
    font-family: Montserrat Alternates;
    font-size: 23.714px;
    font-style: normal;
    font-weight: 600;
    line-height: 152.9%; /* 36.259px */
    text-transform: lowercase;
}
.example{
    padding-bottom: 10px;
    padding-top: 10px;
    color: #54000E;
font-family: Montserrat Alternates;
font-size: 27.402px;
font-style: normal;
font-weight: 800;
line-height: 135.9%; /* 37.239px */
text-transform: capitalize;
}
.Bzy{
    color: #54000E;
font-family: Montserrat Alternates;
font-size: 27.402px;
font-style: normal;
font-weight: 800;
line-height: 135.9%;
text-transform: capitalize;
}
.textFourDown{
    padding-top: 20px;
    width: 645.329px;
    color: #54000E;
font-family: Montserrat Alternates;
font-size: 15.307px;
font-style: normal;
font-weight: 600;
line-height: 122.4%; /* 18.736px */
text-transform: lowercase;
}