.forBlock {
    margin: 0 auto;
    width: 1024px;
    height: 2300px;
    flex-shrink: 0;
    background: #FFCDC6;
}

.imgForMain {
    margin-top: -150px;
    position: absolute;
}

.textForMain {
    padding: 50px;
    color: #54000E;
    font-family: Montserrat Alternates;
    font-size: 66.448px;
    font-style: normal;
    font-weight: 800;
    line-height: 149.9%;
    /* 99.606px */
    text-transform: uppercase;
}

.textForSecond {
    position: relative;
    margin-left: 400px;
    margin-top: -50px;
    color: #54000E;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 52.353px;
    font-style: normal;
    font-weight: 800;
    line-height: 149.9%;
    /* 78.477px */
    text-transform: uppercase;
}

.forTextMedico {
    padding-top: 700px;
    width: 989px;
    color: #54000E;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 38.192px;
    font-style: normal;
    font-weight: 800;
    line-height: 149.9%;
    /* 57.249px */
    text-transform: uppercase;
}

.Block {
    margin-left: 350px;
    margin-top: -750px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    flex-flow: wrap;
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.18)) drop-shadow(0px 9px 19px rgba(0, 0, 0, 0.18)) drop-shadow(0px 34px 34px rgba(0, 0, 0, 0.15)) drop-shadow(0px 78px 47px rgba(0, 0, 0, 0.09)) drop-shadow(0px 138px 55px rgba(0, 0, 0, 0.03)) drop-shadow(0px 216px 60px rgba(0, 0, 0, 0.00));
    width: 621px;
    height: 458px;
    flex-shrink: 0;
    border-radius: 30px;
    background: #FFCDC6;
}

.miniBlock1 {
    width: 250.078px;
    height: 77.61px;
    flex-shrink: 0;
    border-radius: 21.558px;
    border: 3.593px solid #54000E;
}

.textMini1 {
    color: #54000E;
    text-align: center;
    font-family: Montserrat;
    font-size: 26.416px;
    font-style: normal;
    font-weight: 700;
    line-height: 104.4%;
    /* 38.144px */
}

.blockBoll {
    margin-left: 300px;
    margin-top: 500px;

}

.imgForBoll {
    margin-top: -120px;
    margin-left: 45px;
    width: 331px;
    height: 331px;
    flex-shrink: 0;
    border-radius: 331px;
}

.boll {
    border-radius: 519px;
    border: 2px solid #54000E;
    width: 419px;
    height: 419px;
    flex-shrink: 0;
}

.imgMiniBoll1 {
    margin-top: -100px;
    margin-left: 200px;
}

.imgMiniBoll2 {
    margin-top: 150px;
    margin-left: -12px;
}

.imgMiniBoll3 {
    margin-top: -20px;
    margin-left: 200px;
}

.imgMiniBoll4 {
    position: absolute;
    margin-top: -270px;
    margin-left: 405px;
}

.miniText1 {
    margin-left: 20px;
    margin-top: -510px;
    color: #54000E;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 10%;
    /* 24.6px */
    text-transform: lowercase;
}

.miniText2 {
    margin-top: 180px;
    margin-left: -550px;
    color: #54000E;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 10%;
    /* 24.6px */
    text-transform: lowercase;
}

.miniText3 {
    margin-top: -75px;
    margin-left: 600px;
    color: #54000E;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 82%;
    /* 24.6px */
    text-transform: lowercase;
}

.miniText4 {
    margin-top: 250px;
    color: #54000E;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 82%;
    /* 24.6px */
    text-transform: lowercase;
}

.blockEthenshen {
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    display: flex;
    margin-top: 100px;


}

.imgEtn {
    width: 62px;
    height: 62px;
    flex-shrink: 0;

}

.textEtn {
    width: 849px;
    color: #54000E;
    font-family: Montserrat Alternates;
    font-size: 23.605px;
    font-style: normal;
    font-weight: 800;
    line-height: 149.9%;
    /* 35.384px */
    text-transform: uppercase;

}

.contraindicationsBlock {
    margin: 0 auto;
    margin-top: 70px;
    width: 633px;
    height: 260px;
    flex-shrink: 0;
    border-radius: 22.933px;
    background: #FF2147;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.18), 0px 6px 13px 0px rgba(0, 0, 0, 0.18), 0px 25px 25px 0px rgba(0, 0, 0, 0.15), 0px 55px 33px 0px rgba(0, 0, 0, 0.09), 0px 98px 39px 0px rgba(0, 0, 0, 0.03), 0px 153px 43px 0px rgba(0, 0, 0, 0.00);
}

.contraindicationsMainText {
    text-align: center;
    color: #FFF;
    font-family: Montserrat Alternates;
    font-size: 39.792px;
    font-style: normal;
    font-weight: 800;
    line-height: 149.9%;
    /* 59.648px */
    text-transform: uppercase;
}
.whiteBollBlockMain{
    display: flex;
    flex-wrap: wrap;
    align-items:center;
    justify-content:space-evenly;
    flex-flow:row;
}

.whiteBollBlock1 {
    display: flex;
    flex-wrap: wrap;
    align-items:center;
    justify-content:center;
    flex-flow:column;

}

.whiteBoll {
    padding: 20px;
    width: 28px;
    height: 28px;
    flex-shrink: 0;
}

.whiteText1 {
    color: #FFF;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 20.993px;
    font-style: normal;
    font-weight: 700;
    line-height: 50%; /* 19.607px */
    text-transform: lowercase;
}
.whiteBollBlock2{
    margin-top: -60px;
    display: flex;
    flex-wrap: wrap;
    align-items:center;
    justify-content:center;
    flex-flow:column;
}