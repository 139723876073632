.container {
    padding-top: 100px;
    margin: 0 auto;
    width: 1024px;
    height: 873px;
    flex-shrink: 0;
    background: #6DFFFF;
}

.textMain {
    color: #54000E;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 69.804px;
    font-style: normal;
    font-weight: 800;
    line-height: 149.9%;
    /* 104.636px */
    text-transform: uppercase;
}

.textSecond {
    padding-bottom: 50px;
    color: #54000E;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 52.353px;
    font-style: normal;
    font-weight: 800;
    line-height: 191.9%;
    /* 100.466px */
    text-transform: uppercase;
}

.videoYouTubeTrainingFree {
    margin: 0 auto;
    width: 857px;
    height: 482px;
    flex-shrink: 0;
    border-radius: 30px;
    background: #FFCDC6;
}

.blockOnCourse {
    padding-top: 100px;
    width: 1024px;
    height: 1500px;
    flex-shrink: 0;
    background: #6DFFFF;
}

.onCourse {
    margin: 0 auto;
    width: 968px;
    height: 1450px;
    flex-shrink: 0;
    border-radius: 16.42px;
    border: 2.737px solid #54000E;
}

.onCourseTextMain {
     margin-top: 40px;
    padding-left: 50px;
    color: #54000E;
    font-family: Montserrat Alternates;
    font-size: 95.067px;
    font-style: normal;
    font-weight: 800;
    /* 142.505px */
    text-transform: uppercase;
}

.onCourseTextSecond {
    padding-left: 130px;
    color: #54000E;
    font-family: Montserrat Alternates;
    font-size: 85.759px;
    font-style: normal;
    font-weight: 800;
    /* 128.553px */
    text-transform: uppercase;
}

.DescriptioBlockOne {
    padding-left: 10px;
    margin-top: 50px;

}

.textDescriptionOne {
    width: 630.414px;
    color: #54000E;
    font-family: Montserrat;
    font-size: 31.267px;
    font-style: normal;
    font-weight: 600;
    line-height: 93.4%;
    /* 29.203px */
    font-variant: small-caps;

}

.textDescriptionTwo {
    width: 787px;
    color: #54000E;
    font-family: Montserrat;
    font-size: 31px;
    font-style: normal;
    font-weight: 600;
    line-height: 93.4%;
    /* 28.954px */
    font-variant: small-caps;

}

.ImgOneForTrialTaining {
    margin-top: -420px;
    margin-left: 395px;
    width: 600px;
    height: 600px;
    flex-shrink: 0;
}

.textDescriptionBlockTwo {
    padding-left: 10px;
    width: 787px;
    color: #54000E;
    font-family: Montserrat;
    font-size: 31px;
    font-style: normal;
    font-weight: 600;
    line-height: 93.4%;
    /* 28.954px */
    font-variant: small-caps;
}

.ImgTwoForTrialTaining {
    width: 500px;
    height: 500px;
    flex-shrink: 0;
    margin-top: -350px;
    margin-left: 495px;

}

.textDescriptionBlockThree {
    padding-left: 10px;
    width: 787px;
    color: #54000E;
    font-family: Montserrat;
    font-size: 31px;
    font-style: normal;
    font-weight: 600;
    line-height: 93.4%;
    /* 28.954px */
    font-variant: small-caps;
}

.ImgThreeForTrialTaining {
    margin-top: -300px;
    margin-left: 540px;
    width: 457px;
    height: 547px;
    flex-shrink: 0;

}
.blockEthenshen {
    margin-top: 20px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    display: flex;
    margin-left:-10px;


}

.imgEtn {
    width: 62px;
    height: 62px;
    flex-shrink: 0;

}

.textEtn {
    width: 849px;
    color: #54000E;
    font-family: Montserrat Alternates;
    font-size: 23.605px;
    font-style: normal;
    font-weight: 800;
    line-height: 149.9%;
    /* 35.384px */
    text-transform: uppercase;

}
