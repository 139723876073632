@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@700&display=swap');


.header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1024px;
    height: 258px;
    flex-shrink: 0;
    background: linear-gradient(180deg, #FFCDC6 0%, #FFF4F3 28.87%, #FFCDC6 97.92%);
    box-shadow: 0px 0px 0px 0px rgba(26, 50, 50, 0.14), 0px 9px 19px 0px rgba(26, 50, 50, 0.14), 0px 35px 35px 0px rgba(26, 50, 50, 0.12), 0px 79px 47px 0px rgba(26, 50, 50, 0.07), 0px 140px 56px 0px rgba(26, 50, 50, 0.02), 0px 218px 61px 0px rgba(26, 50, 50, 0.00);
}   


  
.titleMain {
    width: 933px;
    height: 70px;
    color: #ECBEB7;
    text-align: center;
    text-shadow: -70px -8.572527385761024e-15px 140px rgba(51, 41, 40, 0.24);
    font-family: Montserrat;
    font-size: 60.938px;
    font-style: normal;
    font-weight: 700;
    line-height: 191.9%;
    /* 116.939px */
    text-transform: uppercase;
    opacity: 0.6;
    font-family: 'Montserrat', sans-serif;
}



.titleSecond {
    color: #51000E;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 29.768px;
    font-style: normal;
    font-weight: 700;
    line-height: 191.9%;
    /* 57.124px */
    font-family: 'Montserrat Alternates', sans-serif;
}
