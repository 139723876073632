.onCourseBlock {
    width: 1024px;
    height: 1900px;
    flex-shrink: 0;
    background: #FFCDC6;
}

.titleMain {
    padding-top: 50px;
    margin-right: 250px;
    color: #54000E;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 95.067px;
    font-style: normal;
    font-weight: 800;
    line-height: 149.9%;
    /* 142.505px */
    text-transform: uppercase;
}

.titleSecond {
    margin-left: 300px;
    color: #54000E;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 95.067px;
    font-style: normal;
    font-weight: 600;
    line-height: 149.9%;
    /* 142.505px */
    text-transform: uppercase;
}

.blockOne {
    margin: 30px auto;
    width: 669px;
    height: 192.453px;
    flex-shrink: 0;
    border-radius: 24.057px;
    background: #FF2147;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.26), 0px 5.61321px 12.83019px 0px rgba(0, 0, 0, 0.26), 0px 23.25472px 23.25472px 0px rgba(0, 0, 0, 0.22), 0px 52.12264px 31.27358px 0px rgba(0, 0, 0, 0.13), 0px 93.01887px 36.88679px 0px rgba(0, 0, 0, 0.04), 0px 145.14151px 40.89622px 0px rgba(0, 0, 0, 0.00);
}

.one {
    margin: 0 10px;
    position: absolute;
    width: 107.907px;
    color: #FFF;
    text-align: center;
    text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.26), 0px 8.820755004882812px 19.245283126831055px rgba(0, 0, 0, 0.26), 0px 35.28302001953125px 35.28302001953125px rgba(0, 0, 0, 0.22), 0px 78.58490753173828px 47.31132125854492px rgba(0, 0, 0, 0.13), 0px 140.33018493652344px 56.132076263427734px rgba(0, 0, 0, 0.04), 0px 218.91510009765625px 61.74528503417969px rgba(0, 0, 0, 0.00);
    font-family: Montserrat Alternates;
    font-size: 265.652px;
    font-style: normal;
    font-weight: 800;
    line-height: 191.9%;
    /* 509.787px */
    text-transform: uppercase;
}

.pOne {
    margin-top: -222px;


}

.textBlockOne {
    padding-top: 10px;
    text-align: center;

}

.titleOne {
    color: #FFF;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 25px;
    font-style: normal;
    font-weight: 900;
    line-height: 174.9%;
    /* 43.725px */
    text-transform: uppercase;
}

.descriptionOne {
    display: flex;
    justify-content: flex-end;
    margin-right: 30px;
    color: #FFF;
    font-family: Montserrat Alternates;
    font-size: 16.54px;
    font-style: normal;
    font-weight: 500;
    line-height: 174.9%;
    text-transform: uppercase;
}



.blockTwo {
    margin: 100px auto;
    width: 669px;
    height: 240px;
    flex-shrink: 0;
    border-radius: 30px;
    background: #FF2147;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.26), 0px 7px 16px 0px rgba(0, 0, 0, 0.26), 0px 29px 29px 0px rgba(0, 0, 0, 0.22), 0px 65px 39px 0px rgba(0, 0, 0, 0.13), 0px 116px 46px 0px rgba(0, 0, 0, 0.04), 0px 181px 51px 0px rgba(0, 0, 0, 0.00);
}

.two {
    position: absolute;
    width: 137.378px;
    color: #FFF;
    text-align: center;
    text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.26), 0px 9.477986335754395px 20.679244995117188px rgba(0, 0, 0, 0.26), 0px 37.91194534301758px 37.91194534301758px rgba(0, 0, 0, 0.22), 0px 84.44024658203125px 50.83647918701172px rgba(0, 0, 0, 0.13), 0px 150.78614807128906px 60.3144645690918px rgba(0, 0, 0, 0.04), 0px 235.22640991210938px 66.34590911865234px rgba(0, 0, 0, 0.00);
    font-family: Montserrat Alternates;
    font-size: 285.446px;
    font-style: normal;
    font-weight: 800;
    line-height: 191.9%;
    /* 547.771px */
    text-transform: uppercase;
}

.pTwo {
    margin-left: 480px;
    margin-top: -200px;
}

.textBlockTwo {
    padding-top: 35px;

}

.oneTitleTwo {
    padding-right: 170px;
    color: #FFF;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 23.211px;
    font-style: normal;
    font-weight: 800;
    line-height: 152.9%;
    /* 35.489px */
    text-transform: uppercase;
}

.twoTitleTwo {
    padding-left: 20px;
    color: #FFF;
    font-family: Montserrat Alternates;
    font-size: 18.569px;
    font-style: normal;
    font-weight: 700;
    line-height: 152.9%;
    text-transform: uppercase;
}

.descriptionTwo {
    text-align: center;
    padding-right: 200px;
    color: #FFF;
    font-family: Montserrat Alternates;
    font-size: 16.48px;
    font-style: normal;
    font-weight: 500;
    line-height: 152.9%;
    text-transform: uppercase;
}

.co {
    padding-left: 16.5px;

}


.blockThree {
    margin: 30px auto;
    width: 669px;
    height: 240px;
    flex-shrink: 0;
    border-radius: 30px;
    background: #FF2147;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.26), 0px 7px 16px 0px rgba(0, 0, 0, 0.26), 0px 29px 29px 0px rgba(0, 0, 0, 0.22), 0px 65px 39px 0px rgba(0, 0, 0, 0.13), 0px 116px 46px 0px rgba(0, 0, 0, 0.04), 0px 181px 51px 0px rgba(0, 0, 0, 0.00);
}

.three {
    position: absolute;
    width: 137.378px;
    color: #FFF;
    text-align: center;
    text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.26), 0px 9.477986335754395px 20.679244995117188px rgba(0, 0, 0, 0.26), 0px 37.91194534301758px 37.91194534301758px rgba(0, 0, 0, 0.22), 0px 84.44024658203125px 50.83647918701172px rgba(0, 0, 0, 0.13), 0px 150.78614807128906px 60.3144645690918px rgba(0, 0, 0, 0.04), 0px 235.22640991210938px 66.34590911865234px rgba(0, 0, 0, 0.00);
    font-family: Montserrat Alternates;
    font-size: 285.446px;
    font-style: normal;
    font-weight: 800;
    line-height: 191.9%;
    /* 547.771px */
    text-transform: uppercase;
}

.pthree {
    margin-left: 20px;
    margin-top: -210px;
}

.textBlockThree {
    padding-top: 20px;
}

.oneTitleThree {
    padding-left: 140px;
    width: 555.462px;
    color: #FFF;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 28.739px;
    font-style: normal;
    font-weight: 800;
    line-height: 152.9%;
    /* 43.943px */
    text-transform: uppercase;
}

.TwoTitleThree {
    padding-left: 194px;
    width: 460px;
    color: #FFF;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 23.8px;
    font-style: normal;
    font-weight: 800;
    line-height: 152.9%;
    /* 36.391px */
    text-transform: uppercase;

}

.descriptionThree {
    padding-left: 150px;
    width: 514.196px;
    color: #FFF;
    text-align: center;
    font-family: Montserrat Alternates;
    font-size: 17.631px;
    font-style: normal;
    font-weight: 500;
    line-height: 152.9%;
    /* 26.957px */
    text-transform: uppercase;

}

.blockFour {
    margin: 100px auto;
    width: 669px;
    height: 240px;
    flex-shrink: 0;
    border-radius: 30px;
    background: #FF2147;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.26), 0px 7px 16px 0px rgba(0, 0, 0, 0.26), 0px 29px 29px 0px rgba(0, 0, 0, 0.22), 0px 65px 39px 0px rgba(0, 0, 0, 0.13), 0px 116px 46px 0px rgba(0, 0, 0, 0.04), 0px 181px 51px 0px rgba(0, 0, 0, 0.00);
}

.four {
    position: absolute;
    width: 137.378px;
    color: #FFF;
    text-align: center;
    text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.26), 0px 9.477986335754395px 20.679244995117188px rgba(0, 0, 0, 0.26), 0px 37.91194534301758px 37.91194534301758px rgba(0, 0, 0, 0.22), 0px 84.44024658203125px 50.83647918701172px rgba(0, 0, 0, 0.13), 0px 150.78614807128906px 60.3144645690918px rgba(0, 0, 0, 0.04), 0px 235.22640991210938px 66.34590911865234px rgba(0, 0, 0, 0.00);
    font-family: Montserrat Alternates;
    font-size: 285.446px;
    font-style: normal;
    font-weight: 800;
    line-height: 191.9%;
    /* 547.771px */
    text-transform: uppercase;
}

.pFour {
    margin-left: 450px;
    margin-top: -200px;
}

.textBlockFour {
    padding-top: 50px;
}

.oneTitleFour {
    padding-left: 20px;
    color: #FFF;
    font-family: Montserrat Alternates;
    font-size: 28.737px;
    font-style: normal;
    font-weight: 800;
    line-height: 152.9%;
    /* 43.94px */
    text-transform: uppercase;
}

.twoTitleFour {
    padding-left: 20px;
    color: #FFF;
    font-family: Montserrat Alternates;
    font-size: 35px;
    font-style: normal;
    font-weight: 800;
    line-height: 152.9%;
    text-transform: uppercase;

}

.descriptionFour {
    padding-left: 20px;
    width: 535px;
    color: #FFF;
    font-family: Montserrat Alternates;
    font-size: 15.881px;
    font-style: normal;
    font-weight: 800;
    line-height: 152.9%;
    /* 24.283px */
    text-transform: lowercase;

}


.blockEthenshen {
    margin-top: 20px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    display: flex;
    margin-left: -10px;


}

.imgEtn {
    width: 62px;
    height: 62px;
    flex-shrink: 0;

}

.textEtn {
    width: 854px;
    color: #54000E;
    font-family: Montserrat Alternates;
    font-size: 24.125px;
    font-style: normal;
    font-weight: 800;
    line-height: 119.9%;
    /* 28.926px */
    text-transform: uppercase;

}